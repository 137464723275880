<template>
	<v-container fluid class="grey lighten-5 my-0 px-0 py-0">
		<QProductLibraryAgentSubmenu />
		<v-col cols="12" style="padding-top: 100px">
			<!-- help and wayfinding -->
			<v-col cols="12" class="text-right">
				<!--
                <v-bottom-sheet v-model="sheet">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="pink" v-on="on">
                            <v-icon>fas fa-question-circle</v-icon>
                        </v-btn>
                    </template>
                    <v-sheet class="text-left px-7" height="auto">
                        <div class="py-3" style="max-width:900px; margin:0 auto;">
                            <p class="q-display-2">Product Concepts</p>
                            <p>Product Conecpts is part of the Symmetry Inranet. This section will be editable and updatable by the Marketing team.</p>
                            <p>It will include:</p>
                            <ul>
                                <li>Training documents (PDF) for each product type</li>
                                <li>Training videos for each product type</li>
                                <li>Carriers for each product</li>
                            </ul>
                            <v-btn class="mt-6" flat color="error" @click="sheet = !sheet">close</v-btn>
                        </div>
                    </v-sheet>
                </v-bottom-sheet>
            -->
			</v-col>
			<!-- /help and wayfinding -->
			<template>
				<QTabs
					class="q-text-left lighest_grey"
					background-color="q_symm_blue"
					dark
				>
					<template v-for="(tab, index) in getTabs">
						<v-tab
							v-if="tab.hasDivision"
							:key="`tab_${index}`"
							@click="logTabActivty(tab.key)"
						>
							{{ tab.label }}
							<v-icon right>fas fa-arrow-alt-circle-right</v-icon>
						</v-tab>
						<v-tab-item
							v-if="tab.hasDivision"
							:key="`tab_item_${index}`"
							class="lighest_grey"
						>
							<template v-for="page in tab.pages">
								<Page
									v-if="!page.division || hasDivision(page.division)"
									:key="page.division"
									:slug="page.slug"
								/>
							</template>
						</v-tab-item>
					</template>
				</QTabs>
			</template>
		</v-col>
		<!-- </v-row> -->
	</v-container>
</template>
<script>
import Page from "@/CMS/views/index.vue";
import QProductLibraryAgentSubmenu from "@/components/navigation/Submenus/QProductLibraryAgentSubmenu.vue";
import QTabs from "../../components/navigation/QTabs.vue";

const tabs = [
	{
		label: "Mortgage Protection",
		key: "Mortgage Protection",
		pages: [
			{
				division: "Field",
				slug: "mortgage-protection",
			},
			{
				division: "Corporate",
				slug: "corp-mortgage-protection",
			},
		],
	},
	{
		label: "Final Expense",
		key: "Final Expense",
		pages: [
			{
				division: "Field",
				slug: "final-expense",
			},
			{
				division: "Corporate",
				slug: "corp-final-expense",
			},
		],
	},
	{
		label: "Critical Illness",
		key: "Critical Illness",
		pages: [
			{
				division: "Field",
				slug: "critical-illness",
			},
		],
	},
	{
		label: "Disability Income",
		key: "Disability Income",
		pages: [
			{
				division: "Field",
				slug: "disability-income",
			},
		],
	},
	{
		label: "Debt Free Life",
		key: "Debt Free Life",
		pages: [
			{
				division: "",
				slug: "debt-free-life",
			},
		],
	},
	{
		label: "Quility Retirement Solutions",
		key: "Quility Retirement Solutions",
		pages: [
			{
				division: "Field",
				slug: "annuity",
			},
		],
	},
	{
		label: "IUL",
		key: "IUL",
		pages: [
			{
				division: "Field",
				slug: "iul",
			},
			{
				division: "Corporate",
				slug: "corp-iul",
			},
		],
	},
	{
		label: "Group Plans",
		key: "Group Plans",
		pages: [
			{
				division: "Field",
				slug: "group-plans",
			},
		],
	},
	{
		label: "Medicare Supplement",
		key: "Medicare Supplement",
		pages: [
			{
				division: "Field",
				slug: "medicare-supplement",
			},
		],
	},
	{
		label: "Long Term Care",
		key: "Long Term Care",
		pages: [
			{
				division: "Field",
				slug: "long-term-care",
			},
		],
	},
	{
		label: "Smart Start",
		key: "Smart Start",
		pages: [
			{
				division: "Field",
				slug: "smart-start",
			},
		],
	},
	{
		label: "PCS",
		key: "PCS",
		pages: [
			{
				division: "Corporate",
				slug: "corp-qms",
			},
		],
	},
	{
		label: "Emergency Response",
		key: "Emergency Response",
		pages: [
			{
				division: "Corporate",
				slug: "corp-ers",
			},
		],
	},
	{
		label: "Infinite Banking",
		key: "IBC",
		pages: [
			{
				division: "",
				slug: "ibc",
			},
		],
	},
];

export default {
	components: {
		Page,
		QProductLibraryAgentSubmenu,
		QTabs,
	},
	data: () => ({
		tab: null,
		sheet: false,
	}),
	computed: {
		getTabs() {
			return tabs.map((tab) => ({
				...tab,
				hasDivision: tab.pages.reduce(
					(acc, { division }) =>
						acc || !division || this.hasDivision(division),
					false
				),
			}));
		},
	},
};
</script>

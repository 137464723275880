<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row style="">
            <v-col cols="12" class="px-10">
                <!--<q-filters v-model="filters" :data="contests" :keys="filter_keys"></q-filters>//-->
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                <v-row wrap v-if="needs_group_number">
                    <v-col cols='12'>
                        <page ref="compliance" slug="quility-rx-agreement" :no-template="true"></page>
                    </v-col>
                    <v-col cols="12" class="text-center pb-10">
                        <v-btn @click="getGroupNumber" large color="primary">
                            I agree to these terms.
                            <v-progress-circular indeterminate v-if="loading" size="20"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
                <QTabs dark v-model="tab" v-else>
                    <v-tab>QuilityRx Enrollment</v-tab>
                    <v-tab>Scorecards</v-tab>
                    <v-tab>Resources</v-tab>
                    <v-tab>Leaderboards</v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-row wrap class="pa-4">
                                <v-col cols='12'>
                                    <h4>QuilityRx Enrollment</h4>
                                </v-col>
                                <v-col cols='6'>
                                    Your Group Number is : <b>{{ user.Agent.QuilityRXGroupNumber }}</b>
                                </v-col>
                                <v-col cols='6'>
                                    Since : <b>{{ formatDate(user.Agent.QuilityRXGroupDate) }}</b>
                                </v-col>
                                <v-col cols='12' class="text-center">
                                    <v-card class="text-left">
                                        <v-card-title>Send Client a QuilityRx Prescription Drug Card</v-card-title>
                                        <v-card-text>
                                            <v-form lazy-validation v-model="valid" ref="form" :disabled="sending">
                                                <v-row wrap>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field label="First Name" v-model="invite.FirstName" :rules="[rules.required]"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field label="Last Name" v-model="invite.LastName" :rules="[rules.required]"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field label="Email" v-model="invite.Email" :rules="[rules.required, rules.email]"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field label="Phone" v-model="invite.Phone"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field label="Zip" v-model="invite.Zip"></v-text-field>
                                                    </v-col>
                                                    <v-col cols='12'>
                                                        <v-btn block large color="primary" @click="sendInvite">
                                                            Send Card
                                                            <v-progress-circular indeterminate v-if="sending" size="20"></v-progress-circular>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols='12'>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <score-card-quility-rx :show-submenu="false"></score-card-quility-rx>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row justify="center">
                                <v-btn class="mt-7 font-weight-bold" v-if="!loading" dark @click="downloadDoc" large ><v-icon class="pr-4">fa-solid fa-file-prescription</v-icon>Print At Home Rx Cards</v-btn>
                            </v-row>
                            <page slug="quility-rx" :no-template="true"></page>
                        </v-tab-item>
                        <v-tab-item>
                            <q-quility-rx-leaderboards :show_filters="true"></q-quility-rx-leaderboards>
                        </v-tab-item>
                    </v-tabs-items>
                </QTabs>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/CMS/views/index.vue'
import User from '@/store/Models/User'
import rules from '@/components/utils/ValidationRules'
import ScoreCardQuilityRx from '@/views/MyStats/QScoreCards_QuilityRx.vue';
import QTabs from '../../components/navigation/QTabs.vue'
import QQuilityRxLeaderboards from '@/components/datatables/QQuilityRxLeaderboards.vue'

export default {
    props: [],
    data: function() {
        return {
            loading: false,
            sending: false,
            valid: true,
            rules: rules,
            tab: 0,
            invite: {
                FirstName: null,
                LastName: null,
                Zip: null,
                Phone: null,
                Email: null,
                GroupCode: null,
            },
            stat_agent: null,
            ag: null
        }
    },
    mounted: function() {
        this.stat_agent = { ...this.user.Agent };
        this.invite.GroupCode = this.user.Agent.QuilityRXGroupNumber
        this.resetInvite();
    },
    computed: {
        'needs_group_number': function() {
            return typeof this.user.Agent.QuilityRXGroupNumber == 'undefined' || this.user.Agent.QuilityRXGroupNumber == null;
        },
    },
    methods: {
        getGroupNumber: function() {
            for (var i = 1; i < 8; i++) {
                if (this.$refs.compliance.$refs["section" + i][0].acknowledged !== true) {
                    this.showError("You must acknowledge and accept each section! Please check all the boxes.")
                    return false
                }
            }
            QuilityAPI.cancelQRequest("QGetGroupNumber");
            this.loading = true;
            var g = this;
            return QuilityAPI.getRequest('/api/qms/quility_rx/' + this.user.Agent.AgentCode + "/group_number", { 'agreement': 1 }, 'QGetGroupNumber')
                .then(function(json) {
                    g.loading = false;
                    g.user.Agent.QuilityRXGroupNumber = json.data.GroupNumber;
                    g.user.Agent.QuilityRXGroupDate = json.data.QuilityRXGroupDate;
                    User.insert({ data: g.user });
                }).catch(function(error) {
                    g.loading = false;
                    g.showError(error.response.body.message)
                });
        },
        sendInvite: function() {
            var g = this;
            if (!this.$refs['form'].validate()) {
                this.goToTop();
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.sending = false;
                return false;
            }
            this.sending = true;
            return QuilityAPI.postRequest('/api/qms/quility_rx/' + this.user.Agent.AgentCode + "/send_invite", { 'invite': this.invite })
                .then(function(json) {
                    g.sending = false;
                    g.showInfo("RX Card Sent!")
                    g.resetInvite();
                }).catch(function(error) {
                    g.sending = false;
                    if (error.msg) {
                        g.showError(error.msg)
                    }
                    if (error.response && error.response.body.message) {
                        g.showError(error.response.body.message)
                    }
                });
        },
        resetInvite: function() {
            this.invite = {
                FirstName: null,
                LastName: null,
                Zip: null,
                Phone: null,
                Email: null
            }
        },
        changeAgent(agentCode) {
            var g = this
            if (typeof agentCode == 'undefined') {
                agentCode = this.user.Agent.AgentCode
            }
            QuilityAPI.getAgentStateless(agentCode).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.stat_agent = json;
                    return
                }
            }).catch(function(err) {
                console.log(err)
            })
        },
        downloadDoc: function() {
            this.loading = true;
            var g = this;
            return QuilityAPI.postRequest('/api/qms/quility_rx/' + this.user.Agent.AgentCode + "/quility_rx_printable_cards")
                .then(function(json) {
                    const downloadURL = json.data
                    window.open(downloadURL, "_blank");
                    g.loading = false;
                }).catch(function(error) {
                    g.loading = false;
                    // g.showError(error.response.body.message)
                    g.showError('Something went wrong. Please try again later.')
                    console.log('error', error)
                });
        },
    },
    watch: {

    },
    components: {
        Page,
        ScoreCardQuilityRx,
        QTabs,
        QQuilityRxLeaderboards,
    }
}
</script>
<style scoped>
</style>,
QQuilityRxLeaderboards
<template>
    <v-row wrap class="pa-4">
        <v-col cols='12'>
            <h4>MyQuility Enrollment</h4>
        </v-col>
        <v-col cols='12' class="text-center">
            <v-card class="text-left">
                <v-card-text>
                    <v-form lazy-validation v-model="valid" ref="form" :disabled="sending">
                        <v-row wrap>
                            <v-col cols="12" sm="6">
                                <v-text-field label="First Name" v-model="invite.FirstName" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Last Name" v-model="invite.LastName" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Email" v-model="invite.Email" :rules="[rules.required, rules.email]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Phone" v-model="invite.Phone"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field label="Zip" v-model="invite.Zip"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-checkbox v-model="invite.myQuility" :label="`MyQuility`" />
                                <v-checkbox v-model="invite.quilityRx" :label="`Quility RX`" />
                            </v-col>
                            <v-col cols='12'>
                                <v-btn block large color="primary" @click="sendInvite">
                                    Enroll
                                    <v-progress-circular indeterminate v-if="sending" size="20"></v-progress-circular>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            sending: false,
            invite: {
                myQuility: false,
                quilityRx: false,
                Zip: '',
            },
            valid: true,
            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+\..+/.test(value) || 'Invalid email.'
            }
        }
    },
    methods: {
        sendInvite() {
            this.sending = true;
        }
    }
}
</script>

<template>
    <div>
        <q-score-card key="QScoreCardQuilityRxCardsSent" ref="card" title="Cards Sent" api-endpoint="rx_cards_sent" drill-down-label="Agent Breakdown" drill-down-route="Drilldown_QuilityRxCardsSent" card-style="recruiting" :agent-code="agentCode" :filters="filters" v-on:most_current="updateMostCurrent">
            Note: Reflects how many members an agent has enrolled/issued a card through the HQ QuilityRx enrollment center.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardQuilityRxCardsSent",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {},
    computed: {

    },
    methods: {
        updateMostCurrent: function(most_current) {
            this.$emit('most_current', most_current);
        }
    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>

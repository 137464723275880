<template>
    <div class="q-tab-dropdown">
        <v-menu offset-y @click.prevent class="pa-0" eager>
            <template v-slot:activator="{ on, attrs }">
                <span class="q-tab-dropdown__text v-tab" :class="{ 'v-tab--active': isActive }" v-on="on" v-bind="attrs">
                    <slot />
                </span>
            </template>
            
            <v-card>
                <slot name="dropdown" />
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    methods: {
        getChildrenByTag(node, tag) {
            return node.$children.reduce((acc, child) => {
                if (child.$options.name === tag) {
                    acc.push(child);
                } else if (child.$children.length) {
                    return acc.concat(this.getChildrenByTag(child, tag));
                }
                return acc;
            }, []);
        },
    },

    computed: {
        tabs() {
            return this.getChildrenByTag(this, 'v-tab');
        },
        isActive() {
            return this.tabs.some((tab) => tab.isActive)
        }
    }
}
</script>

<style lang="scss">
.q-tab-dropdown {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
        width: 100%;
        height: 100%;
    }
}
</style>
<template>
    <div>
        <v-row justify="center">
            <v-btn class="mt-7 font-weight-bold" v-if="!loading" dark @click="downloadDoc" large>
                <v-icon class="pr-4">fa-solid fa-file-prescription</v-icon>Print At Home Rx Cards
            </v-btn>
        </v-row>
        <QPage slug="quility-rx" :no-template="true" />
    </div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QPage from '@/CMS/views/index.vue'

export default {
    components: {
        QPage,
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        downloadDoc: function () {
            this.loading = true;
            var g = this;
            return QuilityAPI.postRequest('/api/qms/quility_rx/' + this.user.Agent.AgentCode + "/quility_rx_printable_cards")
                .then(function (json) {
                    const downloadURL = json.data
                    window.open(downloadURL, "_blank");
                    g.loading = false;
                }).catch(function (error) {
					if (error.isDemoMode) {
						g.showError(error.msg)
						return
					}
                    g.loading = false;
                    // g.showError(error.response.body.message)
                    g.showError('Something went wrong. Please try again later.')
                    console.log('error', error)
                });
        },
    }
}
</script>
<template>
    <q-quility-rx-leaderboards :show_filters="true" />
</template>

<script>
import QQuilityRxLeaderboards from '@/components/datatables/QQuilityRxLeaderboards.vue'


export default {
    components: {
        QQuilityRxLeaderboards,
    },
}
</script>